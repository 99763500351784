import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

// 获取列表
export const BannerPageList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/BannerList/BannerPageList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 删除数据
export const DeleteBanners = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/BannerList/DeleteBanners`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//获取详情
export const BannerDetails = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v2/BannerList/BannerDetails${params}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 新增数据
export const InsetIntoBanne = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/BannerList/InsetIntoBanne`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 编辑数据
export const UpdateBanner = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/BannerList/UpdateBanner`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}