<template>
    <div class="banner-setting">
        <div style="margin-bottom:10px">
            <el-button type="primary" size="medium" icon="el-icon-plus" @click="edit('add')">添加</el-button>
            <el-button type="danger" size="medium" icon="el-icon-delete" @click="deleteData('all')"
                :disabled="!multipleSelection.length">勾选删除</el-button>
        </div>
        <el-table :data="bannerList" :header-cell-style="{ background: '#f0f0f0', color: '#666' }" v-loading="loading"
            ref="multipleTable" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55">
            </el-table-column>
            <el-table-column align="center" type="index" label="序号" width="50" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="SortID" label="排序" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="ImageUrl" label="图片" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-image style="width: 60px; height: 60px" :src="scope.row.ImageUrl"
                        :preview-src-list="[scope.row.ImageUrl]">
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column align="center" prop="UserIdentityName" label="是否发布" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-tag type="success" v-if="scope.row.Enabled">已发布</el-tag>
                    <el-tag type="danger" v-else>未发布</el-tag>
                </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="180">
                <template slot-scope="scope">
                    <el-button type="primary" size="medium" @click="edit('edit', scope.row)">编辑</el-button>
                    <el-button type="danger" size="medium" @click="deleteData('row', scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination background style="margin-top: 16px;" @current-change="paginationChange" @size-change="sizeChange"
            :current-page.sync="pagination.page" :page-size="pagination.pagesize"
            layout="total, sizes, prev, pager, next, jumper" :total="pagination.total">
        </el-pagination>
        <el-dialog class="deep_dialog" :visible.sync="bannerDialog" width="540px" v-if="bannerDialog">
            <span slot="title">banner图配置</span>
            <el-form :model="bannerInfo" label-width="90px" label-position="left">
                <el-image :src="bannerInfo.ImageUrl" :preview-src-list="[bannerInfo.ImageUrl]"
                    v-if="bannerInfo.ImageUrl" style="width: 375px; height: 150px;margin: 0px 0px 16px 90px">
                </el-image>

                <el-form-item label="Banner图" prop="ImageUrl" required>
                    <input type="file" accept="image/*" style="display:none" @change="handleImageUpload" ref="images" />
                    <el-button type="primary" plain icon="el-icon-upload" @click="$refs.images.click()">上传轮播图
                    </el-button>
                </el-form-item>
                <el-form-item label="排序" prop="SortID" required>
                    <el-input style="width: 380px" v-model="bannerInfo.SortID" auto-complete="off" clearable>
                    </el-input>
                </el-form-item>
                <el-form-item label="主标题" prop="Title">
                    <el-input style="width: 380px" v-model="bannerInfo.Title" auto-complete="off" clearable>
                    </el-input>
                </el-form-item>
                <el-form-item label="副标题" prop="SubTitle">
                    <el-input style="width: 380px" v-model="bannerInfo.SubTitle" auto-complete="off" maxlength="18"
                        clearable>
                    </el-input>
                </el-form-item>
                <el-form-item label="跳转链接" prop="RedirectUrl">
                    <el-input style="width: 380px" v-model="bannerInfo.RedirectUrl" auto-complete="off" clearable>
                    </el-input>
                </el-form-item>
                <el-form-item label="是否发布" prop="Enabled">
                    <el-switch v-model="bannerInfo.Enabled">
                    </el-switch>
                </el-form-item>
                <el-form-item label="备注" prop="Remark">
                    <el-input style="width: 380px" v-model="bannerInfo.Remark" auto-complete="off" maxlength="200"
                        clearable type="textarea">
                    </el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="addTable">保 存</el-button>
                <el-button @click="bannerDialog = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { BannerPageList, DeleteBanners, BannerDetails, UpdateBanner, InsetIntoBanne } from "@/api/sysSettings/bannerSetting";
import { upLoadImgByUrl } from '@/api/common/common'
export default {
    data() {
        return {
            fileList: [],
            //分页控件相关参数
            pagination: {
                page: 1, //当前所处的页码
                pagesize: 10, //每次请求的数量
                total: 0
            },
            loading: false,
            // 列表
            bannerList: [],
            multipleSelection: [],
            bannerDialog: false,
            bannerInfo: {},
            type: ''
        }
    },
    methods: {
        //分页改变
        paginationChange(e) {
            this.pagination.page = e
            this.getBannerList()
        },
        //分页数量改变
        sizeChange(e) {
            this.pagination.pagesize = e
            this.getBannerList()
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleImageUpload(e) {
            var reader = new FileReader();
            reader.readAsDataURL(e.srcElement.files[0]);
            reader.onload = () => {
                upLoadImgByUrl({ FileBase64: reader.result, FileType: 'Other' }).then(res => {
                    this.$set(this.bannerInfo, 'ImageUrl', res.data)
                })
            }
        },
        // 获取列表
        getBannerList() {
            this.loading = true
            let params = {
                pageSize: this.pagination.pagesize,
                pageIndex: this.pagination.page
            }
            BannerPageList(params).then(res => {
                this.bannerList = res.data.DataList
                this.pagination.total = res.data.TotalCount
            }).finally(() => {
                this.loading = false
            })
        },
        deleteData(type, row) {
            this.$confirm('确定删除此数据吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let ids = []
                if (type == 'row' && row) {
                    ids = [row.Id]
                } else {
                    this.multipleSelection.map(it => {
                        ids.push(it.Id)
                    })
                }
                const loading = this.$loading({
                    lock: true,
                    text: '删除中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                DeleteBanners(ids).then(res => {
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.getBannerList()
                }).finally(() => {
                    loading.close()
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        edit(type, row) {
            this.type = type
            if (type == 'edit') {
                BannerDetails(row.Id).then(res => {
                    this.bannerInfo = res.data || {}
                    this.bannerDialog = true
                })
            } else {
                this.bannerInfo = {}
                this.bannerInfo.Enabled = true
                this.bannerDialog = true
            }
        },
        addTable() {
            if (!this.bannerInfo.ImageUrl) {
                this.$message({
                    type: 'warning',
                    message: '请上传banner图!'
                });
                return
            }
            if (!this.bannerInfo.SortID) {
                this.$message({
                    type: 'warning',
                    message: '请填写排序号!'
                });
                return
            }
            const loading = this.$loading({
                lock: true,
                text: '保存中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let ajaxFun = this.type == 'add' ? InsetIntoBanne : UpdateBanner
            // ShowType 1为平台 2为小程序
            ajaxFun({ ...this.bannerInfo, ShowType: 2 }).then(res => {
                this.$message({
                    type: 'success',
                    message: '保存成功!'
                });
                this.getBannerList()
                this.bannerDialog = false
            }).finally(() => {
                loading.close()
            })
        }
    },
    created() {
        // 获取列表
        this.getBannerList()
    },
}
</script>

<style lang="scss">
.addDialog {
    .el-dialog__footer {
        border-top: 0 !important;
    }

    .el-dialog__header {
        border-bottom: 1px solid #eee;
    }

    .el-dialog__footer {
        text-align: right !important;
    }
}
</style>

<style lang="scss" scoped>
.addDialog {
    ::v-deep .el-dialog__header {
        border-bottom: 1px solid #eee;
    }

    ::v-deep .el-dialog__footer {
        border-top: 1px solid #eee;
        text-align: center;
    }

    ::v-deep .el-dialog__body {
        padding: 20px;
    }
}
</style>