<template>
    <div class="logoff">
        <el-form class="manageForm" :model="searchData" :inline="true" label-width="100px">
            <el-form-item label="手机号码：" prop="telPhone">
                <el-input class="fromInp" v-model="searchData.telPhone" placeholder="输入手机号码"></el-input>
            </el-form-item>
            <el-form-item label="身份" prop="ideneityId" label-width="80px">
                <el-select v-model="searchData.ideneityId" @change="searchFrom">
                    <el-option v-for="item in ideneityList" :key="item.ID" :label="item.Name" :value="item.ID">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="状态" prop="status" label-width="80px">
                <el-select v-model="searchData.status" @change="searchFrom">
                    <el-option v-for="item in statusList[activeName]" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="时间：" prop="date" label-width="100px">
                <el-date-picker v-model="searchData.date" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"
                    range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" size="medium" @click="searchFrom()" icon="el-icon-search">搜索</el-button>
                <el-button type="primary" size="medium" @click="resetForm()" icon="el-icon-delete">清空</el-button>
            </el-form-item>
        </el-form>
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="注销中" name="0"></el-tab-pane>
            <el-tab-pane label="已注销" name="1"></el-tab-pane>
            <el-tab-pane label="取消/拒绝" name="2"></el-tab-pane>
        </el-tabs>
        <el-table :data="logoffList" :header-cell-style="{ background: '#f0f0f0', color: '#666' }" v-loading="loading">
            <el-table-column align="center" type="index" label="序号" width="50" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" :prop="col.prop" :label="col.label" show-overflow-tooltip
                v-for="(col, idx) in cols" :key="idx"></el-table-column>
            <!--
                <el-table-column align="center" prop="UserName" label="单位/个人名称" show-overflow-tooltip></el-table-column> 
                <el-table-column align="center" prop="AccountType" label="账号类型" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="UserIdentityName" label="身份" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="TelPhone" label="手机号码" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="AuthStatusName" label="认证状态" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="RegisterTime" label="注册时间" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="SurplusTime" label="自动注销剩余时间" show-overflow-tooltip
                    v-if="activeName == 0"></el-table-column>
                <el-table-column align="center" prop="SurplusTime" label="申请注销时间" show-overflow-tooltip v-else>
                </el-table-column>
                <el-table-column align="center" prop="StatusName" label="注销状态" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="DelTime" label="申请注销时间" show-overflow-tooltip
                    v-if="scope.row.Status == 0"></el-table-column>
                <el-table-column align="center" prop="DelTime" label="注销时间" show-overflow-tooltip
                v-if="scope.row.Status == 1"></el-table-column>
                <el-table-column align="center" prop="DelTime" label="账号恢复时间" show-overflow-tooltip v-if="scope.row.Status == 4"></el-table-column>
                <el-table-column align="center" prop="DelTime" label="取消注销时间" show-overflow-tooltip v-if="scope.row.Status == 3"></el-table-column>
                <el-table-column align="center" prop="DelTime" label="拒绝注销时间" show-overflow-tooltip v-if="scope.row.Status == 2"></el-table-column>
                <el-table-column align="center" prop="UserRemark" label="注销原因" show-overflow-tooltip></el-table-column> -->
            <el-table-column align="center" label="操作" width="180">
                <template slot-scope="scope">
                    <el-button type="primary" size="medium" @click="operateConfig(scope.row, 2)" v-if="activeName == 0">
                        拒绝
                    </el-button>
                    <el-button type="primary" size="medium" @click="operateConfig(scope.row, 1)" v-if="activeName == 0">
                        同意
                    </el-button>
                    <el-button type="primary" size="medium" @click="operateConfig(scope.row, 4)"
                        v-if="activeName == 1 && scope.row.Status != 4">
                        账号恢复
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination background style="margin-top: 16px;" @current-change="paginationChange" @size-change="sizeChange"
            :current-page.sync="pagination.page" :page-size="pagination.pagesize"
            layout="total, sizes, prev, pager, next, jumper" :total="pagination.total">
        </el-pagination>
    </div>
</template>

<script>
import { LogoffList, LogoffVerify } from "@/api/sysSettings/logoff";
import { getSysList } from "@/api/power/system";
export default {
    data() {
        return {
            cols: [{
                prop: 'UserName',
                label: '单位/个人名称'
            }, {
                prop: 'AccountType',
                label: '账号类型'
            }, {
                prop: 'UserIdentityName',
                label: '身份'
            }, {
                prop: 'TelPhone',
                label: '手机号码'
            }, {
                prop: 'AuthStatusName',
                label: '认证状态'
            }, {
                prop: 'RegisterTime',
                label: '注册时间'
            }, {
                prop: 'SurplusTime',
                label: '自动注销剩余时间(天)'
            }, {
                prop: 'StatusName',
                label: '注销状态'
            }, {
                prop: 'DelTime',
                label: '申请注销时间'
            }, {
                prop: 'UserRemark',
                label: '注销原因'
            }],
            activeName: '0',
            //分页控件相关参数
            pagination: {
                page: 1, //当前所处的页码
                pagesize: 10, //每次请求的数量
                total: 0
            },
            loading: false,
            ideneityList: [], // 身份列表
            statusList: [
                [
                    {
                        value: '0',
                        label: '申请中'
                    },
                ],
                [
                    {
                        value: '',
                        label: '全部'
                    },
                    {
                        value: '1',
                        label: '已注销'
                    },
                    {
                        value: '4',
                        label: '已恢复'
                    },
                ],
                [
                    {
                        value: '',
                        label: '全部'
                    },
                    {
                        value: '3',
                        label: '已取消'
                    },
                    {
                        value: '2',
                        label: '已拒绝'
                    },
                ],
            ], // 状态列表
            // 搜索条件
            searchData: {
                status: '0',
                telPhone: "",
                date: ["", ""],
                ideneityId: ''
            },
            // 注销列表
            logoffList: []
        }
    },
    methods: {
        searchFrom() {
            this.loading = true
            this.pagination.page = 1
            this.GetLogoffList()
        },
        // 清空搜索
        resetForm() {
            this.searchData = {
                status: '0',
                telPhone: "",
                date: ["", ""],
                ideneityId: ''
            }
            this.pagination.page = 1
            this.GetLogoffList()
        },
        handleClick() {
            this.searchData.status = this.activeName == 0 ? '0' : ''
            this.pagination.page = 1
            this.GetLogoffList()
        },
        //分页改变
        paginationChange(e) {
            this.pagination.page = e
            this.loading = true
            this.GetLogoffList()
        },
        //分页数量改变
        sizeChange(e) {
            this.pagination.pagesize = e
            this.searchFrom()
        },
        // 获取身份列表
        getSysListData() {
            getSysList().then(res => {
                this.ideneityList = res.data
            })
        },
        // 获取注销列表
        GetLogoffList() {
            this.loading = true
            let status = []
            if (this.searchData.status) {
                status = [Number(this.searchData.status)]
            } else {
                if (this.activeName == 1) {
                    status = [1, 4]
                } else {
                    status = [2, 3]
                }
            }
            let params = {
                telPhone: this.searchData.telPhone,
                ideneityId: this.searchData.ideneityId,
                startTime: this.searchData.date ? this.searchData.date[0] : '',
                endTime: this.searchData.date ? this.searchData.date[1] : '',
                pageSize: this.pagination.pagesize,
                pageIndex: this.pagination.page,
                status
            }
            LogoffList(params).then(res => {
                this.logoffList = res.data.DataList
                this.pagination.total = res.data.TotalCount
                this.logoffList.map(it => {
                    this.cols[6].label = this.activeName != 0 ? '申请注销时间' : '自动注销剩余时间(天)'
                    this.cols[6].prop = this.activeName != 0 ? 'CreateTime' : 'SurplusTime'
                    if (it.Status == 0) {
                        this.cols[8].label = '申请注销时间'
                    } else {
                        this.cols[8].label = '状态变更时间'
                    }
                })
            }).finally(() => {
                this.loading = false
            })
        },
        // 操作按钮
        operateConfig(item, status) {
            this.$confirm('确定对此条数据进行该项操作吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const loading = this.$loading({
                    lock: true,
                    text: '请求中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                })
                let params = {
                    id: item.ID,
                    status,
                }
                LogoffVerify(params).then(res => {
                    loading.close()
                    this.$message.success("操作成功")
                    this.GetLogoffList()
                }).catch(() => {
                    loading.close()
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        }
    },
    created() {
        // 获取注销列表
        this.GetLogoffList()
        // 获取身份列表
        this.getSysListData()
    },
}
</script>

<style lang="scss">
.addDialog {
    .el-dialog__footer {
        border-top: 0 !important;
    }

    .el-dialog__header {
        border-bottom: 1px solid #eee;
    }

    .el-dialog__footer {
        text-align: right !important;
    }
}
</style>

<style lang="scss" scoped>
.addDialog {
    ::v-deep .el-dialog__header {
        border-bottom: 1px solid #eee;
    }

    ::v-deep .el-dialog__footer {
        border-top: 1px solid #eee;
        text-align: center;
    }

    ::v-deep .el-dialog__body {
        padding: 20px;
    }
}
</style>