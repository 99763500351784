<template>
    <div>
        <div class="facilityBox">
            <el-tabs v-model="activeName">
                <el-tab-pane label="基础配置" name="1"></el-tab-pane>
                <el-tab-pane label="banner图配置" name="2">
                    <bannerSetting></bannerSetting>
                </el-tab-pane>
                <el-tab-pane label="小程序注销" name="3">
                    <logoff></logoff>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import logoff from "@/components/businessCmpt/sysSetting/logoff";
import bannerSetting from "@/components/businessCmpt/sysSetting/bannerSetting";
export default {
    data() {
        return {
            activeName: '2'
        }
    },
    components: {
        logoff,
        bannerSetting
    }
}
</script>

<style lang="scss" scoped>
</style>